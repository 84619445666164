function FooterSection() {
    return (
        <section className="container-fluid footer_section">

            <div className="footer-text-sdr" style={{display: "none"}}>
                <p className="footer-text">Seller Drive feels proud and overwhelmed by aiding sellers in every step of the way starting with Shopify store launch, complete account management on Amazon, global selling, enhancing visibility, advertisement, content, reporting, and list goes on. Our qualified team will be delighted to back you up making your business grow with boosted brand value leading to progression in sales and growth.
                    Seller Drive will be your “Drive to Success”</p>
            </div>
            <div className="clr"></div>
            <p>
                Copyright 2023 <a href="/" target="_blank" referrerPolicy="no-referrer" >Supply Central</a>
            </p>
        </section>
    )
}

export default FooterSection;