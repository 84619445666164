import './static/style/responsive.css'
import './static/style/style.css'

import HeroArea from "./sections/hero";
import StoreSection from './sections/store';
import ProductSection from './sections/product';
import ClientSection from './sections/client';
import InfoSection from './sections/info';
import FooterSection from './sections/footer';

function App() {
  return (
    <>
      <HeroArea />
      <StoreSection />
      <ProductSection />
      <ClientSection />
      {/* <InfoSection /> */}
      <FooterSection />
    </>
  )
}

export default App;
