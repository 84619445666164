function CarousalItem({active = false}) {

    let carousalItemClassName =  active ? "carousel-item active" : "carousel-item"
    let clientImage = require('../static/images/client.png')

    return (
        <div className={carousalItemClassName}>
            <div className="row layout_padding2">
                <div className="col-md-6">
                    <div className="client_box">
                        <div className="client_id-box">
                            <div className="client_img-box">
                                <img src={clientImage} alt="client" />
                            </div>
                            <h4>Carlac liyo</h4>
                        </div>
                        <div className="client_detail">
                            <p>
                                There are many variations of passages of Lorem Ipsum
                                available, but the majority have suffered alteration in some
                                form, by injected humour, or randomised words which don't look
                                even slightly believable. If you are going to use a passage of
                                Lorem Ipsum, you need to be sure there isn't anything
                                embarrassing hidden in the middle of text.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="client_box">
                        <div className="client_id-box">
                            <div className="client_img-box">
                                <img src={clientImage} alt="client" />
                            </div>
                            <h4>Carlac liyo</h4>
                        </div>
                        <div className="client_detail">
                            <p>
                                There are many variations of passages of Lorem Ipsum
                                available, but the majority have suffered alteration in some
                                form, by injected humour, or randomised words which don't look
                                even slightly believable. If you are going to use a passage of
                                Lorem Ipsum, you need to be sure there isn't anything
                                embarrassing hidden in the middle of text.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ClientSection() {
    return (
        <section className="client_section layout_padding">
            <div className="container">
                <h2>
                    Action speaks louder
                </h2>
                <div id="carouselExample2Controls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <CarousalItem active={true} />
                        <CarousalItem />
                        <CarousalItem />
                    </div>
                    <a className="carousel-control-prev" href="#carouselExample2Controls" role="button" data-slide="prev">

                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExample2Controls" role="button" data-slide="next">

                        <span className="sr-only">Next</span>
                    </a>
                </div>

            </div>
        </section>
    )
}

export default ClientSection