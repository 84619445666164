import supplyCentralLogo from "../static/images/supply-central-logo.png";
import storeBanner from "../static/images/store-banner.png";
import storeBannerMobile from "../static/images/store-banner-mobile.png";

function AnnouncementBar() {
    return (
        <div id="shopify-section-announcement-bar" className="shopify-section mount-announcement store-top-strip">
            <span className="announcement">
                <span className="announcement__text">
                    Enquiry: <a href="tel:+18285475183">+1 828 547 5183</a> | <a href="mailto:contact@supplycentral.store">contact@supplycentral.store</a>
                </span>
            </span>
        </div>
    )
}

function openNav() {
    document.getElementById("myNav").style.width = "100%";
}

function closeNav() {
    document.getElementById("myNav").style.width = "0%";
}

function BannerImage() {
    return (
        <>
            <img
                className="hp-banner-desktop"
                src={storeBanner}
                width="100%"
                alt="store-banner"
            />
            <img
                className="hp-banner-mobile"
                src={storeBannerMobile}
                width="100%"
                alt="store-banner-mobile"
            />
        </>
    )
}

function NavigationBar() {
    return (
        <div className="container-fluid">
            <nav className="navbar navbar-expand-lg custom_nav-container">
                <a className="navbar-brand" href="/">
                    <img
                        src={supplyCentralLogo}
                        alt="Supply Central Store"
                    />
                </a>

                <div className="navbar-collapse" id="">
                    <div className="container">
                        <div className=" mr-auto flex-column flex-lg-row align-items-center">
                            <ul className="navbar-nav justify-content-between ">

                                <div className="d-none d-lg-flex">
                                </div>

                                <div className="d-none d-lg-flex">
                                    <form className="form-inline my-2 ml-5 mb-3 mb-lg-0">
                                        <button className="btn my-2 my-sm-0 nav_search-btn" type="button" />
                                    </form>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div className="custom_menu-btn">
                        <button onClick={openNav} />
                    </div>

                    <div id="myNav" className="overlay">
                        <a href="javascript:void(0)" className="closebtn" onClick={closeNav} >&times;</a>
                        <div className="overlay-content">
                            <a href="#">HOME</a>
                            <a href="#">PRODUCTS</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

function Header() {
    return (
        <header className="header_section store-header">
            <AnnouncementBar />
            <NavigationBar />
        </header>
    )
}

function HeroArea() {
    return (
        <div className="hero_area">
            <Header />
            <BannerImage />
        </div>
    )
}

export default HeroArea;