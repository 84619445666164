import iconVerified from "../static/images/icon-verified-2.png";
import iconQuality from "../static/images/icon-quality-2.png";
import iconTopRated from "../static/images/icon-top-rated-3.png";


function StoreSectionTextual() {
    return (
        <section className="store-section-2">
            <div className="store-section-2-inner">
                <div className="store-section-2-text">
                    <h2>
                        Who we are?<br />
                        A captial of top-rated products<br /> that you can trust
                    </h2>
                    <p>We have partnered with a wide range of verified sellers, and have hand-picked a scale of genuine products with high product ratings and reviews. We work to ensure that the authentic products should reach the relevant audience.</p>
                </div>
            </div>
            <div className="clr"></div>
        </section>
    )
}

function StoreSectionVisual() {
    return (
        <section className="store-section-3">
            <div className="store-section-3-inner">
                <div className="store-section-3-text">
                    <h3>Things that make us reliable.</h3>
                    <h5>Our mission is to help trusted sellers around the globe to sell genuine products in the online space.</h5>
                </div>

                <div className="store-section-3-boxes">
                    <h3>Verified Sellers</h3>
                    <img src={iconVerified} alt="verified" />
                </div>
                <div className="store-section-3-boxes">
                    <h3>Quality Assurance</h3>
                    <img src={iconQuality} alt="quality assurance" />
                </div>
                <div className="store-section-3-boxes">
                    <h3>Top Rated Products</h3>
                    <img src={iconTopRated} alt="top rated" />
                </div>
            </div>
            <div className="clr"></div>
        </section>
    );
}
function StoreSection() {
    return (
        <>
            <StoreSectionTextual />
            <StoreSectionVisual />
        </>
    )
}

export default StoreSection;